input[type=number]{
    background: linear-gradient(180deg, #D8D8D8 0%, #FFFFFF 43.75%, #F2F2F2 100%);
    border: none;
    outline: none;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    border-radius: 6px;

    position: static;
    width: 57px;
    height: 19px;
    left: 7.5px;
    top: 2.5px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;
}

.dimInput {

}






.btnNextStep {
    position: absolute;
    z-index: 1;
    top: 352px;
    left: 903px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../img/step_button.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    width: 134px;
    height: 45px;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #595454;
    text-decoration: none;
}

.planMaker {
    text-align: center;
    position: unset;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    margin: 100px;
}

.points {
    position: relative;
    z-index: 7;
}

.thermostat {
    width: 35px;
    height: 35px;
    background: url("../../img/ThermostatButton/thermostat.svg");
    position: static;
}

.thermostat2 {
    width: 12px;
    height: 1px;
    border-radius: 50%;
    background: red;
    position: static;
}

.thermostat3 {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: green;
    position: static;
}





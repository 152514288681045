.btnNextStep {
    position: absolute;
    z-index: 1;
    top: 352px;
    left: 903px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../img/step_button.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    width: 134px;
    height: 45px;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #595454;
    text-decoration: none;
}

.slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 400%;
    height: 100%;
    animation: animate 7.5s linear infinite;
}

.slide {
    width: 25%;
    height: 100%;
    float: left;
    background-size: cover;
    background-position: center;
}

.slide1 {
    background-image: url("../../img/slider/slider_1.jpg");
}

.slide2 {
    background-image: url("../../img/slider/slider_2.jpg");
}

.slide3 {
    background-image: url("../../img/slider/slider_3.jpg");
}

@keyframes animate {
    0% {
        left: 0;
    }
    25% {
        left: 0;
    }
    33% {
        left: -100%;
    }
    58% {
        left: -100%;
    }
    66% {
        left: -200%;
    }
    91% {
        left: -200%;
    }
    99% {
        left: -300%;
    }
    100% {
        left: -300%;
    }
}
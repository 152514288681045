body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    caret-color: transparent;
}

html {

    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

body {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
}

a, a:visited, a:hover, a:active {
    color: black;
    text-decoration: none;
}

.mobile {
    visibility: hidden;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    color: black;
    display: flex;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    align-items: center;
    justify-content: center;
    opacity: 1;
    pointer-events: none;
    transition: 0.5s;
    z-index: 20;
}

@media screen and (max-width: 1020px) {
    .mobile {
        visibility: visible;
    }
}

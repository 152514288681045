.btnNextStep {
    position: absolute;
    z-index: 1;
    top: 352px;
    left: 903px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../img/step_button.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    width: 134px;
    height: 45px;
    filter: drop-shadow(0px 4px 2px rgba(0, 0, 0, 0.25));
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #595454;
    text-decoration: none;
}

.planMaker {
    text-align: center;
    position: unset;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    margin: 100px;
}

.point {
    width: 0px;
    height: 0px;
    text-align: center;
}

.point:before {
    content: "";
    width: 13px;
    height: 13px;
    background: linear-gradient(143.81deg, #DDDDDD 23.02%, #FFFFFF 102.04%);
    border: 1px solid #A39999;
    box-sizing: border-box;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    position: absolute;
    top: -4.5px;
    left: -4.5px;
}

.points {
    position: relative;
    z-index: 7;
}

.pointStart::before {
    background: #868686;
    border: 0;
}


.dimContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;

    position: absolute;
    width: 57px;
    height: 19px;
    left: calc(50% - 57px / 2 - 128.5px);

    background: linear-gradient(180deg, #D8D8D8 0%, #FFFFFF 43.75%, #F2F2F2 100%);
    border: 0.3px solid #D5D5D5;
    box-sizing: border-box;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    border-radius: 6px;
    z-index: 100;
}

.dimContainer span {
    position: static;
    width: 41px;
    height: 14px;
    left: 7.5px;
    top: 2.5px;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #000000;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30.5px 0px;
}

.lines {
    z-index: 2;
}

s.dimensions {
    z-index: 100;
}





.butT {
    width: 30px;
    height: 30px;
    position: absolute;
    background: no-repeat;
    background-size: cover;
    z-index: 100;
}







